const employeeRoutes = [  
    {
        name: 'Task',
        path: '/manage/employees/tasks',
        icon: 'ApartmentOutlined'
    },
    {
        name: 'Order',
        path: '/manage/employees/orders',
        icon: 'FileTextOutlined'
    },
    {
        name: 'Calendar',
        path: '/manage/employees/calendar',
        icon: 'CalendarOutlined'
    },
]
export default employeeRoutes