<template>
    <div class="flex_layout_row">
        <div class="flex_left left_menu">
            <div class="logo">
                <img src="/img/logo.jpg" class="logo_icon">
            </div>
            <div @click="to(menu.path)" :class="['menu_label', getOn(menu)]" :key="idx" v-for="(menu, idx) in employeeRoutes">
                <div class="menu_name">
                    <component :is="$antIcons[menu.icon]" />
                    {{menu.name}}
                </div>
            </div>
        </div>
        <div class="flex_body">
            <div class="flex_layout">
                <div class="flex_top admin_topbar">
                    <div class="user_info">
                        <div class="avatar">
                            <a-avatar class="primary">
                                <template #icon>
                                   <component :is="$antIcons['UserOutlined']" />
                                </template>
                            </a-avatar>
                        </div>
                        <div class="username">Hi, welcome, {{getUsername}}</div>
                        <a-space>
                            <a-button @click="logout" danger shape="round" size="small">
                                <template #icon>
                                <component :is="$antIcons['PoweroffOutlined']" />
                                </template>
                            </a-button>
                        </a-space>
                    </div>
                </div>
                <div class="flex_body">
                    <router-view />
                </div>
                <div class="flex_bottom">AMGT copyright</div>
            </div>
        </div>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import commonMixins from '@/mixins/commonMixins'
import employeeRoutes from '@/config/employee.routes'
import storage from '@/utils/storage'
export default {
    name: 'Index',
    mixins: [commonMixins],
    data(){
        return {
            employeeRoutes: [],
            spinning: false
        }
    },
    methods: {
        to(path){
            this.$router.push({path})
        },
        async logout(){
            storage.clear()
            message.success('success')
            setTimeout(() => {
                this.$router.push({path: '/login'})
            }, 1000)
        }
    },
    computed: {
        getOn(){
            return menu => {
                return {on : this.$route.path.indexOf(menu.path) > -1}
            }
        },
        getUsername(){
            return storage.getLocalData('username')
        }
    },
    created(){
        if(this.getAction(1)){
            this.employeeRoutes = employeeRoutes
        }else{
            this.employeeRoutes = [employeeRoutes[0]]
        }
    }
}
</script>
<style lang="stylus" scoped>
.flex_bottom
    text-align center
    background #fff
    color #999
</style>